import { createApp } from "vue";
import { createAuth0 } from "@auth0/auth0-vue";
import App from "./App.vue";
import * as Sentry from "@sentry/vue";
import router from "./router";
import vuetify from "./plugins/vuetify";
import "@/assets/style-library/styles.scss";
// @ts-ignore
import Vue3EasyDataTable from "vue3-easy-data-table";
import "vue3-easy-data-table/dist/style.css";
import { createPinia } from "pinia";
import piniaPluginPersistedstate from "pinia-plugin-persistedstate";
import i18n from "@/plugins/vi18n/index";
const pinia = createPinia();
pinia.use(piniaPluginPersistedstate);

const auth0CallbackURL = window.origin + "/callback";
const app = createApp(App);

Sentry.init({
    app,
    dsn: import.meta.env.VITE_SENTRY_DSN,
    release: `${import.meta.env.VITE_APP_NAME}@${
        import.meta.env.VITE_APP_VERSION
    }`,
    tracePropagationTargets: [import.meta.env.VITE_API_SERVER_URL, /^\//],
    integrations: [
        Sentry.browserTracingIntegration({
            router,
            routeLabel: "name",
        }),
        Sentry.replayIntegration({
            // masks PI from inputs/text on session replays
            // can be altered to allow only certain inputs to be hidden
            // more on https://docs.sentry.io/platforms/javascript/guides/vue/session-replay/privacy/
            maskAllText: true,
        }),
    ],
    debug: import.meta.env.VITE_ENVIRONMENT !== "production",
    logErrors: import.meta.env.VITE_ENVIRONMENT === "production" ? false : true,
    attachProps: true,
    attachStacktrace: true,
    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    environment: import.meta.env.VITE_ENVIRONMENT,
    enabled: import.meta.env.VITE_ENVIRONMENT !== "local",
    /** List taken from Sentry's gist https://docs.sentry.io/platforms/javascript/configuration/filtering/#decluttering-sentry */
    ignoreErrors: [
        "Non-Error promise rejection captured",
        // Random plugins/extensions
        "top.GLOBALS",
        // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
        "originalCreateNotification",
        "canvas.contentDocument",
        "MyApp_RemoveAllHighlights",
        "http://tt.epicplay.com",
        "Can't find variable: ZiteReader",
        "jigsaw is not defined",
        "ComboSearch is not defined",
        "http://loading.retry.widdit.com/",
        "atomicFindClose",
        // Facebook borked
        "fb_xd_fragment",
        // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to
        // reduce this. (thanks @acdha)
        // See http://stackoverflow.com/questions/4113268
        "bmi_SafeAddOnload",
        "EBCallBackMessageReceived",
        // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
        "conduitPage",
        // Network errors such as going offline or being blocked by a proxy
        "Failed to fetch",
        /**
         * error triggered by IP addresses from Microsoft Corporation. This seems to be the issue:
         * "antifingerprint not defined yet. will try and handle event after its ready."
         * No solution/cause yet, but threads recommend to filter out the error:
         * https://github.com/getsentry/sentry-javascript/issues/9143
         * 688 events triggered in Sentry for 688 distinct users. The ip addresses point to Microsoft Corporation
         * */
        "TypeError: Illegal invocation",
        "TypeError: cancelled", // See https://stackoverflow.com/a/70452078
    ],
    denyUrls: [
        // Safari webkit
        /.*webkit-masked-url.*/,
        /^webkit-masked-url:\/\/hidden\/$/,
        // Chrome extensions
        /extensions\//i,
        /^chrome:\/\//i,
        /^chrome-extension:\/\//i,
        // Other plugins
        /webappstoolbarba\.texthelp\.com\//i,
        /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
        // Firefox extensions
        /^resource:\/\//i,
    ],
    // maximum number of characters a message can have
    // before it will be truncated
    maxValueLength: 1000,
});

const auth = createAuth0({
    domain: import.meta.env.VITE_AUTH0_DOMAIN,
    clientId: import.meta.env.VITE_AUTH0_CLIENT_ID,
    authorizationParams: {
        redirect_uri: auth0CallbackURL,
        audience: import.meta.env.VITE_AUTH0_AUDIENCE,
        useRefreshTokens: true,
    },
});

app.use(pinia)
    .use(router)
    .use(vuetify)
    .use(i18n)
    .use(auth as any)
    .mount("#app");

app.component("EasyDataTable", Vue3EasyDataTable);
